import './App.css';
import Nominator from "./Nominator";

function App() {
  return (
    <Nominator/>
  );
}

export default App;
